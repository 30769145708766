import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { data } from "jquery";
// import { stat } from "fs";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  // adminLocalURL="http://localhost:8080/";
  adminURL = environment.admin_url;
  userURL = environment.admin_url + "admin/orders/notContributed?value";
  webUrl = `https://oldapi.delivermytune.com/`;
  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      Authorization: "my-request-token",
      "Content-Type": "application/json",
    }),
    withCredentials: true,
  };

  handleError(error: HttpErrorResponse) {
    let errorMessage = "Unknown error!";
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  getAllArtists() {
    return this.http
      .get(this.adminURL + "admin/artists", this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }

  getAllArtistList() {
    return this.http
      .get(this.adminURL + "admin/artists/getAllArtist", this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }

  getAllArtistss(details) {
    return this.http
      .get(
        this.adminURL +
        `admin/artists?page=${details.page}&pageSize=${details.pageSize}`,
        this.httpOptions
      )
      .pipe(retry(3), catchError(this.handleError));
  }
  getArtistAll(details) {
    return this.http
      .get(
        this.adminURL +
        `admin/artists?search=${details.search}&page=${details.page}&pageSize=${details.pageSize}`,
        this.httpOptions
      )
      .pipe(retry(3), catchError(this.handleError));
  }

  getBlockedUsers(dtls: any) {
    const params = new HttpParams({
      fromObject: {
        search: dtls.search,
        dateFrom: dtls.dateFrom,  
        dateTo: dtls.dateTo,   
        songType: dtls.songType,   
        page: dtls.page.toString(),
        pageSize: dtls.pageSize.toString()
      }
    });

    return this.http.get(this.adminURL + 'admin/artists/blockedUsers', {
      headers: this.httpOptions.headers,
      params: params
    }).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  getRoyalty() {
    return this.http
      .get(this.adminURL + "admin/royalty", this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  getRoyaltySum() {
    return this.http
      .get(this.adminURL + "admin/royalty/sum", this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  addRoyalty(data) {
    const headers = new HttpHeaders({});

    headers.append("Content-Type", "multipart/form-data; charset=utf-8;");
    const formData = new FormData();
    formData.append("status", data.status);
    formData.append("month", data.month);
    formData.append("year", data.year);
    formData.append("user_id", data.user_id);
    formData.append("value", data.value);
    formData.append("file", data.file, data.file_name);
    const options = { headers, withCredentials: true };

    return this.http
      .post(this.adminURL + "admin/royalty", formData, options)
      .pipe(retry(3), catchError(this.handleError));
  }
  editRoyalty(data) {
    const headers = new HttpHeaders({});

    // headers.append('Content-Type', 'multipart/form-data; charset=utf-8;');
    const formData = new FormData();
    formData.append("status", data.status);

    const options = { headers, withCredentials: true };

    return this.http
      .put(
        this.adminURL + "admin/royalty/status/" + data._id,
        formData,
        options
      )
      .pipe(retry(3), catchError(this.handleError));
  }
  editStatusRoyalty(data) {
    const headers = new HttpHeaders({});
    const options = { headers, withCredentials: true };

    return this.http
      .put(this.adminURL + "admin/royalty/statusUpdate", data, options)
      .pipe(retry(3), catchError(this.handleError));
  }
  deleteRoyalty(id) {
    const headers = new HttpHeaders({});

    const options = { headers, withCredentials: true };

    return this.http
      .delete(this.adminURL + "admin/royalty/" + id, options)
      .pipe(retry(3), catchError(this.handleError));
  }
  addEMR(data) {
    const headers = new HttpHeaders({});

    headers.append("Content-Type", "multipart/form-data; charset=utf-8;");
    const formData = new FormData();
    formData.append("month", data.month);
    formData.append("year", data.year);
    formData.append("file", data.file, data.file_name);
    const options = { headers, withCredentials: true };

    return this.http
      .post(this.adminURL + "admin/royalty/file ", formData, options)
      .pipe(catchError(this.handleError));
  }

  getCampaignReport() {
    return this.http
      .get(this.adminURL + "admin/campaign", this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }

  getAuthTokenByUserId(id: string) {
    return this.http
      .get(this.adminURL + "admin/users/loginas/" + id, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }

  addCampaignReport(data) {
    const headers = new HttpHeaders({
      Authorization: "my-request-token",
      "Content-Type": "application/json",
    });

    headers.append("Content-Type", "multipart/form-data; charset=utf-8;");
    const formData = new FormData();
    formData.append("user_id", data.user_id);
    formData.append("order_id", data.order_id);
    formData.append("month", data.month);
    formData.append("year", data.year);
    formData.append("platform", data.platform);
    formData.append("impression", data.impression);
    formData.append("engagement", data.engagement);
    formData.append("click", data.click);
    formData.append("file", data.file);
    let options = { headers: headers, withCredentials: true };

    return this.http
      .post(this.adminURL + "admin/campaign", formData, options)
      .pipe(retry(3), catchError(this.handleError));
  }

  getAllFans() {
    return this.http
      .get(this.adminURL + "admin/fans", this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllLabels() {
    return this.http
      .get(this.adminURL + "admin/labels", this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  deleteArtist(id) {
    return this.http
      .delete(this.adminURL + "admin/artists/" + id, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  editArtist(data) {
    return this.http
      .put(this.adminURL + "admin/artists/" + data._id, data, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  deleteFan(id) {
    return this.http
      .delete(this.adminURL + "admin/fans/" + id, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  editFan(data) {
    return this.http
      .put(this.adminURL + "admin/fans/" + data._id, data, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  deleteLabel(id) {
    return this.http
      .delete(this.adminURL + "admin/labels/" + id, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  editLabel(data) {
    return this.http
      .put(this.adminURL + "admin/labels/" + data._id, data, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  verifyphone(id) {
    return this.http
      .post(this.adminURL + "admin/artists/phoneverify/" + id, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllPosts() {
    return this.http
      .get(this.adminURL + "admin/posts", this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  deletePost(id) {
    return this.http
      .delete(this.adminURL + "admin/posts/" + id, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllCoupons() {
    return this.http
      .get(this.adminURL + "admin/coupons", this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  createCoupons(data) {
    return this.http
      .post(this.adminURL + "admin/coupons", data, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  editCoupons(data) {
    return this.http
      .put(this.adminURL + "admin/coupons/" + data._id, data, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  deleteCoupons(id) {
    return this.http
      .delete(this.adminURL + "admin/coupons/" + id, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllCampaign() {
    return this.http
      .get(this.adminURL + "admin/adcampaign", this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllQueries() {
    return this.http
      .get(this.adminURL + "admin/queries", this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllPayment() {
    return this.http
      .get(this.adminURL + "admin/payments", this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  getOneQueries(id) {
    return this.http
      .get(this.adminURL + "admin/queries/" + id, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  AddReplyMsg(id, msg) {
    return this.http
      .put(this.adminURL + "admin/queries/" + id, msg, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  Resolve(id) {
    return this.http
      .get(this.adminURL + "admin/queries/" + id + "/resolve", this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllMusicTrack(type) {
    return this.http
      .get(this.adminURL + "admin/orders?type=" + type, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllMusicTracks(type, object) {
    return this.http
      .get(
        this.adminURL +
        "admin/orders?type=" +
        type +
        `&page=${object.page}&pageSize=${object.pageSize}`,
        this.httpOptions
      )
      .pipe(retry(3), catchError(this.handleError));
  }

  // getAllTrackMusic(type, object) {
  //   return this.http
  //     .get(
  //       this.adminURL +
  //       "admin/orders?type=" +
  //       type +
  //       `&search=${object.search}&manager=${object.manager}&page=${object.page}&pageSize=${object.pageSize}`,
  //       this.httpOptions
  //     )
  //     .pipe(retry(3), catchError(this.handleError));
  // }
  getAllTrackMusic(type, object) {
    const searchParam = object.search !== null && object.search !== undefined ? `&search=${object.search}` : '';
    
    return this.http
      .get(
        this.adminURL +
        "admin/orders?type=" +
        type +
        `&manager=${object.manager}&page=${object.page}&pageSize=${object.pageSize}` +
        searchParam,
        this.httpOptions
      )
      .pipe(retry(3), catchError(this.handleError));
  }
  
  getAllTrackMusicDate(type, object) {
    return this.http
      .get(
        this.adminURL +
        "admin/orders?type=" +
        type +
        `&created_at=${object.date}&manager=${object.manager}&page=${object.page}&pageSize=${object.pageSize}`,
        this.httpOptions
      )
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllTrackMusicDateEmail(type, object) {
    return this.http
      .get(
        this.adminURL +
        "admin/orders?type=" +
        type +
        `&created_at=${object.date}&manager=${object.manager}&search=${object.search}&page=${object.page}&pageSize=${object.pageSize}`,
        this.httpOptions
      )
      .pipe(retry(3), catchError(this.handleError));
  }

  deleteAlbum(id) {
    return this.http
      .delete(this.adminURL + "admin/orders/" + id, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllDashboardCount() {
    return this.http
      .get(this.adminURL + "admin/dashboard", this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }

  getAllContact() {
    return this.http
      .get(this.adminURL + "admin/contacts", this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  createIsrc(data) {
    return this.http
      .post(this.adminURL + "admin/counters/isrc", data, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }

  createUpc(data) {
    return this.http
      .post(this.adminURL + "admin/counters/upc", data, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }

  createMasterAudio(data) {
    const headers = new HttpHeaders({
      Authorization: "my-request-token",
    });

    headers.append("Content-Type", "multipart/form-data; charset=utf-8;");
    const formData = new FormData();
    formData.append("user_id", data.user_id);
    formData.append("order_id", data.order_id);
    formData.append("track_id", data.track_id);
    formData.append("file", data.file);
    formData.append("email", data.email);
    formData.append("status", "approved");
    formData.append("createdby", "admin");
    formData.append("songName", data.songName);
    let options = { headers: headers, withCredentials: true };
    return this.http
      .post(this.adminURL + "admin/mastered", formData, options)
      .pipe(retry(3), catchError(this.handleError));
  }
  getSong(id) {
    return this.http
      .get(this.adminURL + "admin/orders/songs/" + id, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  getOrderId(id) {
    return (
      this.http
        .get(this.adminURL + "admin/orders/email/" + id, this.httpOptions)
        // https://admin.delivermytune.com/api/admin/artists/searchArtist?search=yuvraj
        // .get(this.adminURL + "admin/artists/searchArtist?search=" + id, this.httpOptions)
        .pipe(retry(3), catchError(this.handleError))
    );
  }
  getEmail(id) {
    return (
      this.http
        // .get(this.adminURL + "admin/orders/email/" + id, this.httpOptions)
        // https://admin.delivermytune.com/api/admin/artists/searchArtist?search=yuvraj
        .get(
          this.adminURL + "admin/artists/searchArtist?search=" + id,
          this.httpOptions
        )
        .pipe(retry(3), catchError(this.handleError))
    );
  }
  getMasteredAudio() {
    return this.http
      .get(this.adminURL + "admin/mastered", this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  updateMasteredAudio(data) {
    const headers = new HttpHeaders({
      Authorization: "my-request-token",
    });

    headers.append("Content-Type", "multipart/form-data; charset=utf-8;");
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("songName", data.songName);
    let options = { headers: headers, withCredentials: true };
    return this.http
    .put(this.adminURL + `admin/mastered/updatemasteredaudio/${data._id}`, formData, options)
      .pipe(retry(3), catchError(this.handleError));
  }
  getAllmanager() {
    return this.http
      .get(this.adminURL + "admin/orders/getallmanager", this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }
  createWallet(data) {
    return this.http
      .post(this.adminURL + "admin/wallet", data, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }

  changePassword(data) {
    return this.http
      .post(
        this.adminURL + "authentication/admin/changepassword",
        data,
        this.httpOptions
      )
      .pipe(retry(3), catchError(this.handleError));
  }

  getWalletData() {
    return this.http
      .get(this.adminURL + "admin/wallet", this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }

  getUserData() {
    return this.http
      .get(this.adminURL + "admin/users", this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }

  createUserData(data) {
    return this.http
      .post(this.adminURL + "admin/users", data, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }

  updateUserData(data) {
    return this.http
      .put(this.adminURL + "admin/users/" + data._id, data, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }

  // saveComment(id:string, comment: string){
  //   return this.http.put(`${this.adminURL}admin/orders/${id}/comment`, { comment })
  //   .pipe(retry(3), catchError(this.handleError));
  // }

  deleteUserData(id) {
    return this.http
      .delete(this.adminURL + "admin/users/" + id, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }

  addUserPermissionData(data) {
    return this.http
      .post(this.adminURL + "admin/users/permission", data, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }

  changeStatus(data) {
    console.log(data);
    return this.http
      .put(this.adminURL + "admin/orders/" + data._id, data, this.httpOptions)
      .pipe(retry(3), catchError(this.handleError));
  }

  saveComment(id: String, comment: String) {
    console.log(comment);

    return this.http
      .put(`${this.adminURL}admin/orders/${id}/comment`, { comment })
      .pipe(retry(3), catchError(this.handleError));
  }

  downloadFanExcel() {
    window.open(this.adminURL + "admin/fans/csv");
  }

  downloadRoyaltyExcel() {
    window.open(this.adminURL + "admin/royalty/csv");
  }

  downloadRoyaltySumExcel() {
    window.open(this.adminURL + "admin/royalty/sum/csv");
  }

  downloadCamaignExcel(id) {
    let val = "";
    if (id.length > 1) {
      for (let i = 0; i < id.length; i++) {
        if (i == 0) {
          val += "?id=" + id[0];
        } else {
          val += "&id=" + id[i];
        }
      }
    } else if (id.length == 1) {
      val += "/" + id[0];
    }

    console.log(val);
    window.open(this.adminURL + "admin/adcampaign/csv" + val);
  }
  downloadArtistsExcel(id) {
    let val = "";
    for (let i = 1; i < id.length; i++) {
      val += "id=" + id[0] + "&id=" + id[i];
    }
    console.log(val);
    window.open(this.adminURL + "admin/artists/csv?" + val);
  }

  downloadLabelsExcel() {
    window.open(this.adminURL + "admin/labels/csv");
  }
  downloadPayment() {
    window.open(this.adminURL + "admin/payments/csv");
  }

  downloadAlbumExcel(type) {
    window.open(this.adminURL + "admin/orders/csv?type=" + type);
  }

  downloadAlbumExcelSingle(id) {
    window.open(this.adminURL + "admin/orders/orderswithmetadata/" + id);
  }

  downloadMetaData(id) {
    window.open(this.adminURL + "admin/orders/metadata/" + id);
  }
  downloadblockeduserExcel() {
    window.open(this.adminURL + "admin/artists/csvdata");
  }
  searchFilter(event, item) {
    const val = event.target.value.toLowerCase();
    let newArray = [];
    for (var i = 0; i < item.length; i++) {
      var searchToken = true;
      for (var key in item[i]) {
        var stringData = JSON.stringify(item[i][key]).toLowerCase();
        var filterValue = val.toLowerCase();
        if (stringData.indexOf(filterValue) != -1 && searchToken == true) {
          newArray.push(item[i]);
          // console.log(newArray);
          searchToken = false;
        }
      }
    }
    return newArray;
  }

  // Analytics api's

  // 1st table
  getUserLists(object) {
    return this.http.get(
      this.webUrl +
      `admin/orders/getorderbydate?page=${object.page}&pageSize=${object.pageSize}`
    );
  }
  getUserList(object) {
    return this.http.get(
      this.webUrl +
      `admin/orders/getorderbydate?search=${object.search}&signupMonth=${object.signupMonth}&distributedMonth=${object.distributedMonth}&year=${object.year}&page=${object.page}&pageSize=${object.pageSize}`
    );
  }
  getUserListbyMonthYear(object) {
    return this.http.get(
      this.webUrl +
      `admin/orders/getorderbydate?signupMonth=${object.signupMonth}&distributedMonth=${object.distributedMonth}&year=${object.year}&page=${object.page}&pageSize=${object.pageSize}`
    );
  }
  getUserListBySingerName(objt) {
    return this.http.get(
      this.webUrl +
      `admin/orders/getorderbydate?search=${objt.search}&page=${objt.page}&pageSize=${objt.pageSize}`
    );
  }

  // 2nd table
  getDistributionPaymentDetails(obj) {
    return this.http.get(
      this.webUrl +
      `admin/orders/songdetails?page=${obj.page}&pageSize=${obj.pageSize}`
    );
  }
  getDistributionPaymentFilterData(month: any, year: any, objts) {
    return this.http.get(
      this.webUrl +
      `admin/orders/songdetails?month2=${month}&year2=${year}&page=${objts.page}&pageSize=${objts.pageSize}`
    );
  }
  getDistributionPaymentFiltersWithStatus(
    status: any,
    month: any,
    year: any,
    objts
  ) {
    return this.http.get(
      this.webUrl +
      `admin/orders/songdetails?status=${status}&distributedMonth=${month}&year=${year}&page=${objts.page}&pageSize=${objts.pageSize}`
    );
  }
  getDistributionPaymentFiltersWithStatusOnly(status: any, objts) {
    return this.http.get(
      this.webUrl +
      `admin/orders/songdetails?status=${status}&page=${objts.page}&pageSize=${objts.pageSize}`
    );
  }

  // 3rd table
  getRoyaltyPaymentDetails(details) {
    return this.http.get(
      this.webUrl +
      `admin/royalty/lifetimeRoylity?page=${details.page}&pageSize=${details.pageSize}`
    );
  }
  getRoyaltyPaymentDetailss(month: any, year: any, details) {
    return this.http.get(
      this.webUrl +
      `admin/royalty/lifetimeRoylity?month=${month}&year=${year}&page=${details.page}&pageSize=${details.pageSize}`
    );
  }
  getFilteredRoyaltyPaymentDetails(month: any, year: any, details) {
    console.log(year);
    console.log(month);
    return this.http.get(
      this.webUrl +
      `admin/royalty/lifetimeRoylity?month=${month}&year=${year}&page=${details.page}&pageSize=${details.pageSize}`
    );
  }

  // 4th table
  getDistributionPaused(details) {
    console.log(details);
    return this.http.get(
      this.webUrl +
      `admin/orders/notContributed?value=${details.value}&page=${details.page}&pageSize=${details.pageSize}`
    );
  }

  // getFilteredDistributionsPaused(days,dtls) {
  //   console.log(days);
  //   return this.http.get(
  //     this.webUrl + `admin/orders/notContributed?value=${dtls.value}&value=${days}&page=${dtls.page}&pageSize=${dtls.pageSize}`
  //   );
  // }
  getFilteredDistributionPaused(days, dtls) {
    console.log(days);
    return this.http.get(
      this.webUrl +
      `admin/orders/notContributed?value=${days}&page=${dtls.page}&pageSize=${dtls.pageSize}`
    );
  }

  // Download Csv For anylytics tables

  // 1st table
  downloadSignUpVsDistribution() {
    window.open(this.webUrl + `admin/orders/getorderbydate/csv/download`);
  }

  // 2nd table
  downloadDistributionPaymentDetails() {
    window.open(this.webUrl + `admin/royalty/csv/royalty/download`);
  }

  // 3rd table
  downloadRoyaltyPaymentDetails() {
    window.open(this.webUrl + `admin/royalty/csv/royalty/download`);
  }

  // 4th table
  downloadDistributedPausedData() {
    window.open(
      this.webUrl + `admin/orders/notContributed/csv/download?value=30`
    );
  }

  // for socialshare
  getSocialShare() {
    return this.http.get(this.adminURL + `admin/socialshare`);
  }

  getCopyrightArtist(object) {
    return this.http.get(
      this.adminURL +
      `admin/artists/copyright?page=${object.page}&pageSize=${object.pageSize}`
      // this.adminURL + `admin/artists/copyright`
    );
  }
  // getCopyrightArtist(){
  //   return this.http.get(
  //     this.adminURL + `admin/artists/copyright`
  //   );
  // }
  getCopyrightArtistList(object) {
    return this.http.get(
      this.adminURL +
      `admin/artists/copyright/?page=${object.page}&pageSize=${object.pageSize}&search=${object.search}`
      // this.adminURL + `admin/artists/copyright?search=${object.search}`
    );
  }

  getCopyrightArtistListNumber(object) {
    return this.http.get(
      this.adminURL +
      `admin/artists/copyright/?page=${object.page}&pageSize=${object.pageSize}&number=${object.copyright}`
      // this.adminURL + `admin/artists/copyright?search=${object.search}`
    );
  }
  getCopyrightArtistListWithNumber(object) {
    return this.http.get(
      this.adminURL +
      `admin/artists/copyright/?page=${object.page}&pageSize=${object.pageSize}&search=${object.search}&number=${object.copyright}`
      // this.adminURL + `admin/artists/copyright?search=${object.search}`
    );
  }
  deactiveCopyright(data) {
    return this.http.post(
      this.adminURL + "admin/artists/userstatuschange",
      data
    );
  }

  changePlan(data) {
    return this.http.patch(
      this.adminURL + "/admin/artists/updateuserplan",
      data
    );
  }

  getArtistPortfolioAll(object) {
    return this.http.get(
      this.adminURL + `creative?page=${object.page}&pageSize=${object.pageSize}`
    );
  }

  getArtistPortfolioEmail(object) {
    return this.http.get(
      this.adminURL + `creative?email=${object.email}&page=${object.page}&pageSize=${object.pageSize}`
    );
  }

  getArtistPortfolioName(object) {
    return this.http.get(
      this.adminURL + `creative?portfolio=${object.portfolio}&page=${object.page}&pageSize=${object.pageSize}`
    );
  }
  getArtistPortfolio(object) {
    return this.http.get(
      this.adminURL + `creative?email=${object.email}&portfolio=${object.portfolio}&page=${object.page}&pageSize=${object.pageSize}`
    );
  }
}


