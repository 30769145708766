// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // account_url : 'https://stagingapi.delivermytune.com/authentication/admin/',
  account_url: 'https://oldapi.delivermytune.com/authentication/admin/',
  // account_url: 'http://localhost:8080/authentication/admin/',
  // account_url : 'https://dmtapi.anshuagrawal.in/authentication/admin/',

  admin_url: 'https://oldapi.delivermytune.com/',
  // admin_url: 'http://localhost:8080/',
  // admin_url : 'https://dmtapi.anshuagrawal.in/',

  // admin_url : 'https://stagingapi.delivermytune.com/',
  webUrl: 'https://delivermytune.com',
  // webUrl: 'https://dmtapi.anshuagrawal.in/',

  production: false
};
// https://stagingapi.delivermytune.com/admin/artists
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
