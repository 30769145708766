import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  // {
  //   id: 'navigation',
  //   title: 'Navigation',
  //   type: 'group',
  //   icon: 'feather icon-monitor',
  //   children: [
  //     {
  //       id: 'dashboard',
  //       title: 'Dashboard',
  //       type: 'item',
  //       url: '/dashboard/analytics',
  //       icon: 'feather icon-home'
  //     },
  //     {
  //       id: 'page-layouts',
  //       title: 'Horizontal Layouts',
  //       type: 'item',
  //       url: '/layout/horizontal',
  //       target: true,
  //       icon: 'feather icon-layout'
  //     }
  //   ]
  // },
  // {
  //   id: 'ui-element',
  //   title: 'UI ELEMENT & FORMS',
  //   type: 'group',
  //   icon: 'feather icon-layers',
  //   children: [
  //     {
  //       id: 'basic',
  //       title: 'Basic',
  //       type: 'collapse',
  //       icon: 'feather icon-box',
  //       children: [
  //         {
  //           id: 'alert',
  //           title: 'Alert',
  //           type: 'item',
  //           url: '/basic/alert'
  //         },
  //         {
  //           id: 'button',
  //           title: 'Button',
  //           type: 'item',
  //           url: '/basic/button'
  //         },
  //         {
  //           id: 'badges',
  //           title: 'Badges',
  //           type: 'item',
  //           url: '/basic/badges'
  //         },
  //         {
  //           id: 'breadcrumb-pagination',
  //           title: 'Breadcrumbs & Pagination',
  //           type: 'item',
  //           url: '/basic/breadcrumb-paging'
  //         },
  //         {
  //           id: 'cards',
  //           title: 'Cards',
  //           type: 'item',
  //           url: '/basic/cards'
  //         },
  //         {
  //           id: 'collapse',
  //           title: 'Collapse',
  //           type: 'item',
  //           url: '/basic/collapse'
  //         },
  //         {
  //           id: 'carousel',
  //           title: 'Carousel',
  //           type: 'item',
  //           url: '/basic/carousel'
  //         },
  //         {
  //           id: 'grid-system',
  //           title: 'Grid System',
  //           type: 'item',
  //           url: '/basic/grid-system'
  //         },
  //         {
  //           id: 'progress',
  //           title: 'Progress',
  //           type: 'item',
  //           url: '/basic/progress'
  //         },
  //         {
  //           id: 'modal',
  //           title: 'Modal',
  //           type: 'item',
  //           url: '/basic/modal'
  //         },
  //         {
  //           id: 'spinner',
  //           title: 'Spinner',
  //           type: 'item',
  //           url: '/basic/spinner'
  //         },
  //         {
  //           id: 'tabs-pills',
  //           title: 'Tabs & Pills',
  //           type: 'item',
  //           url: '/basic/tabs-pills'
  //         },
  //         {
  //           id: 'typography',
  //           title: 'Typography',
  //           type: 'item',
  //           url: '/basic/typography'
  //         },
  //         {
  //           id: 'tooltip-popovers',
  //           title: 'Tooltip & Popovers',
  //           type: 'item',
  //           url: '/basic/tooltip-popovers'
  //         },
  //         {
  //           id: 'other',
  //           title: 'Other',
  //           type: 'item',
  //           url: '/basic/other'
  //         }
  //       ]
  //     },
  //     {
  //       id: 'forms-element',
  //       title: 'Form Elements',
  //       type: 'item',
  //       url: '/forms/basic',
  //       icon: 'feather icon-file-text'
  //     }
  //   ]
  // },
  // {
  //   id: 'table',
  //   title: 'Table & Charts',
  //   type: 'group',
  //   icon: 'feather icon-list',
  //   children: [
  //     {
  //       id: 'bootstrap',
  //       title: 'Bootstrap Table',
  //       type: 'item',
  //       url: '/tbl-bootstrap/bt-basic',
  //       icon: 'feather icon-server'
  //     },
  //     {
  //       id: 'apex',
  //       title: 'Apex Chart',
  //       type: 'item',
  //       url: '/charts/apex',
  //       icon: 'feather icon-pie-chart'
  //     }
  //   ]
  // },
  // {
  //   id: 'pages',
  //   title: 'Pages',
  //   type: 'group',
  //   icon: 'feather icon-file-text',
  //   children: [
  //     {
  //       id: 'auth',
  //       title: 'Authentication',
  //       type: 'collapse',
  //       icon: 'feather icon-lock',
  //       children: [
  //         {
  //           id: 'signup',
  //           title: 'Sign up',
  //           type: 'item',
  //           url: '/auth/signup',
  //           target: true,
  //           breadcrumbs: false
  //         },
  //         {
  //           id: 'signin',
  //           title: 'Sign in',
  //           type: 'item',
  //           url: '/auth/signin',
  //           target: true,
  //           breadcrumbs: false
  //         },
  //         {
  //           id: 'reset-password',
  //           title: 'Reset Password',
  //           type: 'item',
  //           url: '/auth/reset-password',
  //           target: true,
  //           breadcrumbs: false
  //         },
  //         {
  //           id: 'change-password',
  //           title: 'Change Password',
  //           type: 'item',
  //           url: '/auth/change-password',
  //           target: true,
  //           breadcrumbs: false
  //         }
  //       ]
  //     },
  //     {
  //       id: 'maintenance',
  //       title: 'Maintenance',
  //       type: 'collapse',
  //       icon: 'feather icon-sliders',
  //       children: [
  //         {
  //           id: 'error',
  //           title: 'Error',
  //           type: 'item',
  //           url: '/maintenance/error',
  //           target: true,
  //           breadcrumbs: false
  //         },
  //         {
  //           id: 'coming-soon',
  //           title: 'Maintenance',
  //           type: 'item',
  //           url: '/maintenance/coming-soon',
  //           target: true,
  //           breadcrumbs: false
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   id: 'other',
  //   title: 'Other',
  //   type: 'group',
  //   icon: 'feather icon-align-left',
  //   children: [
  //     {
  //       id: 'menu-level',
  //       title: 'Menu Levels',
  //       type: 'collapse',
  //       icon: 'feather icon-menu',
  //       children: [
  //         {
  //           id: 'menu-level-2.1',
  //           title: 'Menu Level 2.1',
  //           type: 'item',
  //           url: 'javascript:',
  //           external: true
  //         },
  //         {
  //           id: 'menu-level-2.2',
  //           title: 'Menu Level 2.2',
  //           type: 'collapse',
  //           children: [
  //             {
  //               id: 'menu-level-2.2.1',
  //               title: 'Menu Level 2.2.1',
  //               type: 'item',
  //               url: 'javascript:',
  //               external: true
  //             },
  //             {
  //               id: 'menu-level-2.2.2',
  //               title: 'Menu Level 2.2.2',
  //               type: 'item',
  //               url: 'javascript:',
  //               external: true
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       id: 'disabled-menu',
  //       title: 'Disabled Menu',
  //       type: 'item',
  //       url: 'javascript:',
  //       classes: 'nav-item disabled',
  //       icon: 'feather icon-power',
  //       external: true
  //     },
  //     {
  //       id: 'sample-page',
  //       title: 'Sample Page',
  //       type: 'item',
  //       url: '/sample-page',
  //       classes: 'nav-item',
  //       icon: 'feather icon-sidebar'
  //     }
  //   ]
  // }



  {
    id: 'navigation',
    title: 'Actions',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: '/admin/dashboard',
        icon: 'feather icon-home'
      },

      {
        id: 'analytic',
        title: 'Analytic',
        type: 'item',
        url: '/admin/freesong',
        icon: 'feather icon-sliders'
      },

      {
        id: 'fan-layouts',
        title: 'Fan',
        type: 'item',
        url: '/admin/fan',
        icon: 'feather icon-sliders'
      },
      {
        id: 'artist-layouts',
        title: 'Artist',
        type: 'item',
        url: '/admin/artist',
        icon: 'feather icon-file-text'
      },
      {
        id: 'blocked_artist',
        title: 'Blocked Artist',
        type: 'item',
        url: '/admin/blocked-artist',
        icon: 'feather icon-file-text'
      },
      {
        id: 'label-layouts',
        title: 'Label',
        type: 'item',
        url: '/admin/label',
        icon: 'feather icon-layout'
      },
      {
        id: 'payment-history',
        title: 'Payment History',
        type: 'item',
        url: '/admin/payment-history',
        icon: 'feather icon-monitor'
      }
      ,
      {
        id: 'album',
        title: 'Album',
        type: 'item',
        url: '/admin/album',
        icon: 'feather icon-book'
      },
      {
        id: 'music-track',
        title: 'Single Upload',
        type: 'item',
        url: '/admin/music-track',
        icon: 'feather icon-menu'
      },
      {
        id: 'post',
        title: 'Post',
        type: 'item',
        url: '/admin/post',
        icon: 'feather icon-list'
      },
      {
        id: 'royalty-reports',
        title: 'Royalty reports',
        type: 'item',
        url: '/admin/royalty-reports',
        icon: 'feather icon-layers'
      },
      {
        id: 'advertisement',
        title: 'Advertisement',
        type: 'item',
        url: '/admin/advertisement',
        icon: 'feather icon-box'
      },
      {
        id: 'campaign-report',
        title: 'Campaign Report',
        type: 'item',
        url: '/admin/campaign-report',
        icon: 'feather icon-life-buoy'
      },
      {
        id: 'royalty',
        title: 'Royalty',
        type: 'item',
        url: '/admin/royalty',
        icon: 'feather icon-activity'
      },
      {
        id: 'royalty-sum',
        title: 'Royalty Sum',
        type: 'item',
        url: '/admin/royalty-sum',
        icon: 'feather icon-layers'
      },
      {
        id: 'coupon',
        title: 'Coupon',
        type: 'item',
        url: '/admin/coupon',
        icon: 'feather icon-align-left'
      },
      {
        id: 'send-a-query',
        title: 'Send a Query',
        type: 'item',
        url: '/admin/send-a-query',
        icon: 'feather icon-server'
      },
      {
        id: 'contact',
        title: 'Contact',
        type: 'item',
        url: '/admin/contact',
        icon: 'feather icon-sliders'
      },
      {
        id: 'upc',
        title: 'UPC',
        type: 'item',
        url: '/admin/upc',
        icon: 'feather icon-align-right'
      },
      {
        id: 'isrc',
        title: 'ISRC',
        type: 'item',
        url: '/admin/isrc',
        icon: 'feather icon-file-text'
      },
      {
        id: 'mastered_audio',
        title: 'Mastered Audio',
        type: 'item',
        url: '/admin/mastered-audio',
        icon: 'feather icon-play-circle'
      },
      {
        id: 'wallet',
        title: 'Wallet',
        type: 'item',
        url: '/admin/wallet',
        icon: 'feather icon-credit-card'
      },
      {
        id: 'change-credential',
        title: 'Change Dashboard Credential',
        type: 'item',
        url: '/admin/change-credential',
        icon: 'feather icon-play-circle'
      },
      {
        id: 'user-module',
        title: 'User Module',
        type: 'item',
        url: '/admin/user-module',
        icon: 'feather icon-users'
      },
      {
        id: 'social-share',
        title: 'Social Share',
        type: 'item',
        url: '/admin/social-share',
        icon: 'feather icon-share-2'
      },

      // {
      //   id: 'artists-portfolio',
      //   title: 'Artist',
      //   type: 'item',
      //   url: '/admin/artist-portfolio',
      //   icon: 'feather icon-trending-up'
      // },
      {
        id: 'copyright-artists',
        title: 'Copyright Artists',
        type: 'item',
        url: '/admin/copyright-artist',
        icon: 'feather icon-trending-up'
      },
      {
        id: 'artist-portfolio',
        title: 'Artists Portfolio',
        type: 'item',
        url: '/admin/artist-portfolio',
        icon: 'feather icon-users'
      },

      // {
      //   id: 'copyright-artists',
      //   title: 'Copyright Artists',
      //   type: 'item',
      //   url: '/admin/copyright-artist',
      //   icon: 'feather icon-trending-up'
      // },


    ]
  }
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
