import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
declare var toastr: any;

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
  currentUser: any = {username: ""};

  constructor(private route: Router) { }

  ngOnInit() { 
    this.currentUser = JSON.parse(localStorage.getItem('user_details'));
  }

  logout(){
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_details');
    localStorage.removeItem('permission');
    toastr.success('See you soon!')
    this.route.navigate(['']);
  }
}
