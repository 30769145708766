import { Component, Input, NgZone, OnInit } from '@angular/core';
import { NavigationItem } from '../../navigation';
import { Location } from '@angular/common';
import { NextConfig } from '../../../../../../app-config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-group',
  templateUrl: './nav-group.component.html',
  styleUrls: ['./nav-group.component.scss']
})
export class NavGroupComponent implements OnInit {
  @Input() item: NavigationItem;
  @Input() layout1: boolean = false;
  @Input() activeId: any;
  public nextConfig: any;

  constructor(private zone: NgZone, private location: Location, private route: Router) {
    this.nextConfig = NextConfig.config;
  }

  ngOnInit() {
    if(localStorage.getItem('first') == '1') {
      localStorage.setItem('first', '2');
      window.location.reload();
    }
    var itemData = JSON.parse(JSON.stringify(this.item));
    this.item.children = [];
    var permission: any = {};
    if(localStorage.getItem('permission') && localStorage.getItem('permission') != 'undefined') {
      permission = JSON.parse(localStorage.getItem('permission'));
    }
    
    // console.log(permission);
    // console.log(itemData);
    // setTimeout(() => {
      var i = 0;
      itemData.children.forEach(element => {
        // console.log(element);
        // console.log(permission);
        
        for (let key of Object.keys(permission)) {
          var label = key.replace(/_/g, " ");
          label = label.replace("View ", "");
          // console.log("label", label);
          // console.log("key", permission[key]);
          // console.log(element.title);
          // console.log(label);
          if (permission[key] == true && label == element.title) {
            
            console.log(element.title);
            this.item.children.push(JSON.parse(JSON.stringify(element)));
            i++;
            if(i == 1) {
              this.route.navigate([element.url]);
            }
          }
        }
      });
    // }, 1000);

    // at reload time active and trigger link
    let current_url = this.location.path();
    if (this.location['_baseHref']) {
      current_url = this.location['_baseHref'] + this.location.path();
    }
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent.parentElement.parentElement;
      const last_parent = up_parent.parentElement;
      if (parent.classList.contains('pcoded-hasmenu')) {
        if (this.nextConfig['layout'] === 'vertical') {
          parent.classList.add('pcoded-trigger');
        }
        parent.classList.add('active');
      } else if (up_parent.classList.contains('pcoded-hasmenu')) {
        if (this.nextConfig['layout'] === 'vertical') {
          up_parent.classList.add('pcoded-trigger');
        }
        up_parent.classList.add('active');
      } else if (last_parent.classList.contains('pcoded-hasmenu')) {
        if (this.nextConfig['layout'] === 'vertical') {
          last_parent.classList.add('pcoded-trigger');
        }
        last_parent.classList.add('active');
      }
    }
  }

}
